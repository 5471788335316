.pdfContainer{
    padding: 30px;
    width: 100%;
    font-size: 12px;
    color: black;
}
.itemsList{
    border-top: 1px solid #ddd;
    border-left: 1px solid #ddd;
    width: 100%;
}

.itemsList th {
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding: 5px;
    font-size: 12px;
}

.itemsList td {
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding: 5px;
    word-wrap: break-word;
    font-size: 10px;
}

.itemsList .imageColumn {
    width: 22%;
}

.itemsList .descriptionColumn {
    width: 22%;
}

.itemsList .costColumn{
    width: 12%;
    text-align: right;  
}

.itemsList .itemImage{
    max-height: 80px;
    max-width: 100px;
    margin: auto;
}