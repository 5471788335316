.loader-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    z-index: 9999;
}

.bounce-loader {
    transform: scale(0.2);
    position: relative;
    z-index: 2;
}

.position-one {
    left: 0;
}

.position-two {
    right: 0;
}

.position-three {
    bottom: 0;
}

.position-four {
    bottom: 0;
    right: 0;
}

.loader {
    display: flex;
    width: 180px;
    position: relative;
    top: 50px;
    flex-wrap: wrap;
    animation: rotate 19s infinite linear;
}

.circle-container {
    min-width: 70px;
    height: 70px;
    margin: 10px;
    position: relative;
}

.circle {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    animation: bounce 0.35s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(3600deg);
    }
}

@keyframes bounce {
    from {
        margin: 0;
    }
    to {
        margin: -50px;
    }
}