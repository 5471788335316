.order-history-tooptip {
  opacity: 1;
  background: #ffffff;
  border: 1px solid var(--grey300);
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
}
.order-history-tooptip .rc-tooltip-arrow {
  top: -10px !important;
  margin-left: -6px !important;
  border-width: 0 10px 10px !important;
}
.order-history-tooptip .rc-tooltip-inner {
  border: none !important;
  background-color: #ffffff;
}
.order-id-tooltip .preview-container {
  padding: 10px;
  width: 210px;
  height: 250px;
  overflow-y: scroll;
}

.order-id-tooltip .preview-tooltip {
  padding: 10px;
  width: 210px;
  height: unset;
  overflow-y: unset;
  position: relative;
}

.order-id-tooltip .footer-divider {
  align-self: center;
  background-color: #e5e5e5;
  height: 1px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.order-id-tooltip .linkText {
  color: var(--linkBlue);
  text-align: center;
  display: block;
  font-size: 12px;
}

.order-id-tooltip .linkText:hover {
  color: var(--linkBlue);
}

.order-id-tooltip .order-item-title {
  margin-top: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: var(--grey900);
  font-weight: 500;
}
