


.table-filter .form-select__control {
    line-height: 26px;
    width: 140px;
    font-size: 13px;
}

.form-select__menu-list {
    font-size: 13px;
    overflow-x: hidden;
}