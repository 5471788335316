.table-multi-table-filter .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
  height: 34px;
  position: relative;
  font-size: 14px;
}

.table-multi-table-filter .MuiOutlinedInput-root.MuiInputBase-sizeSmall > .no-scrollbar {
  flex: 5;
}

.table-multi-table-filter input.MuiOutlinedInput-input {
  /* width: 80% !important; */
  position: relative;
}

.async-table-filter-listbox li .MuiCheckbox-root {
  min-width: 38px;
  padding-left: 3px !important;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.MuiAutocomplete-root .MuiOutlinedInput-root {
  padding-right: 2.5rem;
}

.MuiAutocomplete-clearIndicator {
  margin-right: -12px;
}

.MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input {
  text-overflow: unset;
}

.MuiAutocomplete-tagSizeSmall {
  width: 28px;
  height: 22px;
  border-radius: 7px;
  text-align: center;
  font-size: 14px;
  background-color: #28a5e3;
  color: white;
  font-weight: 700;
  margin-bottom: 5px !important;
  margin-left: 2px !important;
}

.async-multi-table-filter-wrapper {
  position: relative !important;
  z-index: 1000000 !important;
}

.tagSpan {
  height: 32px;
  margin-top: 6px;
  max-width: 100% !important;
  text-overflow: clip;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block !important;
}

.table-multi-table-filter .MuiAutocomplete-endAdornment .MuiButtonBase-root {
  padding: 4px;
  font-size: 0.8rem;
}

.MuiDataGrid-columnHeaderTitleContainerContent {
  width: 100% !important;
}

.table-multi-table-filter {
  min-width: 60px !important;
}

.MuiDataGrid-columnHeaderTitleContainerContent > div {
  width: 100% !important;
  max-width: fit-content !important;
}

.MuiDataGrid-iconButtonContainer {
  z-index: 10;
  width: 32px !important;
}

.MuiDataGrid-columnHeaderTitleContainer {
  margin-right: 1.5rem !important;
}

.MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer {
  margin-right: 0 !important;
}

.MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderDraggableContainer {
  width: auto !important;
}