.order-detail {
  padding: 24px;
  background-color: #fff !important;
}

.order-detail-heading {
  border-bottom: 1px solid #eff2f7;
  padding: 10px 0;
  font-size: 22px;
  margin-bottom: 10px;
  font-weight: 600;
  color: #58c9f9 !important;
  width: 300px;
}

.order-detail-heading-sub {
  width: 100%;
  font-size: 23px;
  margin-top: 26px;
}

.order-detail-spacer {
  font-size: 18px;
  font-weight: normal;
  padding-left: 15px;
  padding-right: 4px;
}

.order-detail-title {
  padding-left: 10px;
  width: 120px;
  font-size: 18px;
  display: inline-block;
}

.order-detail-value {
  font-size: 18px;
  display: inline-block;
  font-weight: 600;
}

.order-detail-description {
  max-width: 250px;
  word-break: break-all;
}

.order-detail-items {
  padding-top: 15px;
}

.order-detail-items .custom-table-bordered {
  border: 1px solid #000000 !important;
}

.order-detail-items .custom-table-bordered > thead > tr > th,
.order-detail-items .custom-table-bordered > tbody > tr > th,
.order-detail-items .custom-table-bordered > tfoot > tr > th,
.order-detail-items .custom-table-bordered > thead > tr > td,
.order-detail-items .custom-table-bordered > tbody > tr > td,
.order-detail-items .custom-table-bordered > tfoot > tr > td {
  border: 1px solid #000000 !important;
}

.table {
  width: 100%;
  font-size: 14px;
}

.table thead > tr > th,
.table tbody > tr > th,
.table tfoot > tr > th,
.table thead > tr > td,
.table tbody > tr > td,
.table tfoot > tr > td {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd;
}

th {
  text-align: left !important;
}

.table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
}
