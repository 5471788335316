.heading{
    text-align: left;
    letter-spacing: 0px;
    opacity: 1;
    font-family: Helvetica;
    font-weight: bold;
    font-size: 22px;
    margin: 0;
    padding: 0;
}

.content{
    margin-top: 12px !important;
    text-align: left;
    font-size: 20px;
    color:  #707070;
}

.remove-margin-top{
    margin-top: 0px;
}

.button{
    height: 51px !important;
    border-radius: 3px !important;
    opacity: 1 !important;
}

.term-and-policy{
    margin-top: 35px;
    font-family: Arial, Helvetica, sans-serif;
    color: #666666
}
