
.async-table-filter-listbox li .MuiCheckbox-root {
  min-width: 38px;
  padding-left: 3px !important;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.MuiAutocomplete-root .MuiOutlinedInput-root {
  padding-right: 2.5rem;
}

.MuiAutocomplete-clearIndicator {
  margin-right: -12px;
}

.MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input {
  text-overflow: unset;
}

.table-multi-table-filter .MuiAutocomplete-endAdornment .MuiButtonBase-root {
  padding: 4px;
  font-size: 0.8rem;
}

.MuiDataGrid-columnHeaderTitleContainerContent {
  width: 100% !important;
}

.table-multi-table-filter {
  min-width: 120px !important;
}

.MuiDataGrid-columnHeaderTitleContainerContent>div {
  width: 100% !important;
  max-width: fit-content !important;
}

.MuiDataGrid-iconButtonContainer {
  z-index: 10;
  width: 32px !important;
}