.form-phone-input .special-label {
  display: block !important;
}

.form-phone-input .form-control {
  border-radius: 8px !important;
  padding-left: 76px !important;
}

.form-phone-input .form-control:disabled {
  background-color: var(--grey200);
}
.react-tel-input .form-control {
  border: 1px solid var(--grey500) !important;
}
.form-phone-input .form-control:disabled + .flag-dropdown {
  background-color: var(--grey200);
}

.form-phone-input .form-control:not(:disabled):active,
.form-control:not(:disabled):hover {
  box-shadow: 0 0 0 1px var(--blue) !important;
  border-color: var(--blue) !important;
}
.form-phone-input .form-control:disabled:hover {
  box-shadow: none;
  outline: none !important;
  border: 1px solid var(--grey400);
  /* border-color: green; */
}

.form-phone-input .react-tel-input .country-list .country-name {
  margin-left: 30px;
}

.form-phone-input .react-tel-input .flag-dropdown {
  border: 1px solid var(--grey500);
  background-color: var(--white);
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.form-phone-input .react-tel-input .flag-dropdown .selected-flag {
  width: 68px;
  padding-left: 22px;
}

.form-phone-input .react-tel-input .flag {
  transform: scale(1.5);
}
