.heading{
    text-align: left;
    letter-spacing: 0px;
    opacity: 1;
    font-family: Helvetica;
    font-weight: bold;
    font-size: 22px;
    margin: 0;
    padding: 0;
}

.heading2{
    text-align: left;
    font-family: Helvetica;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #666666;
    opacity: 1;
    margin-top: 31px;
}

.button{
    margin-left: 15px;
    margin-top: 15px;
    font-family: Helvetica;
    height: 51px !important;
    border-radius: 3px !important;
    opacity: 1 !important;
    font-weight: 700 !important;
}

.delete-description{
    margin-top: 22px;
    font-family: Helvetica;
    font-size: 16px;
    color: #707070;
    text-align: left;
    letter-spacing: 0px;
    opacity: 1;
}
