.chartClass div svg foreignObject .apexcharts-legend{
  height: 100px;
}
.chartClass div svg foreignObject div {
  display: block;
  scroll-behavior: smooth;
  overflow-y: hidden;
}
.graphic-container {
  min-height: 300px;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}

.apexcharts-inner apexcharts-graphical{
  scroll-behavior: smooth;
}

.datapointList:hover {
  background-color: rgb(236, 236, 236);
}
