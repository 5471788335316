.app-unified-header {
  font-family: Inter, sans-serif;
}

.desktop-menu {
  display: flex;
}
.mobile-menu {
  display: none;
}

@media screen and (max-width: 1360px) {
  .desktop-menu {
    display: none;
  }
  .mobile-menu {
    display: flex;
  }
}
