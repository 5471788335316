new-user .sweet-alert .modal-title {
  font-size: 20px !important;
  font-weight: 500 !important;
  font-family: 'Open Sans', sans-serif !important;
  margin: 0;
  line-height: 1.42857143;
  text-align: left;
  color: #66ccff;
  display: block;
  padding-right: 40px;
}
.sweet-alert .modal-title{
  color: var(--grey900) !important;
  font-family: "Inter" !important;
}

.sweet-alert > h2 {
  position: relative;
}

.sweet-alert .modal-body {
  position: relative;
  padding: 30px 15px 15px 0px;
  font-size: 14px !important;
  font-weight: bold !important;
  text-align: left;
  color: #797979;
}

.sweet-alert > p {
  padding: 15px 15px 0px 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 0 !important;
}

.sweet-alert > p button.btn {
  margin-right: 0 !important;
  margin-left: 16px;
  font-weight: 400;
  border-radius: 5px;
  padding: 6px 12px;
  font-family: 'Inter';
  padding: 10px 12px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
}

.sweet-alert {
  padding: 15px !important;
  margin-top: -176px !important;
}

.sweet-alert .modal-body.modal-body-close {
  padding-top: 0;
  padding-right: 25px;
}

.sweet-alert > h2 .body-close-btn + button.close {
  transform: none;
  top: -9px;
}

.sweet-alert > h2 {
  font-size: 2rem;
}

.sweet-alert > h2 button.close {
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
  border: 0 none;
  color: red;
  font-size: 30px;
  opacity: 1;
  padding: 0;
  margin: 0 !important;
  float: right;
  font-weight: 700;
  line-height: 1;
  text-shadow: 0 1px 0 #fff;
  position: absolute;
  right: 0;
  top: calc(50% + -3px);
  transform: translateY(-50%);
  z-index: 9 !important;
  cursor: pointer !important;
}

.close {
  opacity: 0.2;
  font-size: 21px;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  color: #000;
  text-decoration: none;
  opacity: 0.5;
}

.btn-success {
  background: #66cc33 !important;
}

.btn {
  border: 0;
  font-weight: normal;
  padding: 7px 12px;
  font-size: 14px;
  box-shadow: none !important;
  font-family: 'Open Sans', sans-serif;
}

.btn-success:hover {
  background-color: lighten(#66cc33, 5%) !important;
}

.btn-danger {
  background: #ff6c60 !important;
}

.btn-danger:hover {
  background-color: lighten(#ff6c60, 3%) !important;
}

.button,
.btn,
.btn:focus,
.btn.focus {
  box-shadow: none;
  outline: none;
}

.btn-success,
.btn-info,
.btn-danger {
  color: #fff;
}
