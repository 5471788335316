.order-request-form .MuiRadio-root {
  width: 40px;
}
.order-request-form .MuiFormControlLabel-root {
  display: block;
}

.bulk-order-link,
.apply-to-all-link {
  font-weight: 600;
  font-size: 13px;
  cursor: pointer;
}

.order-item-in-stock-input .MuiOutlinedInput-root {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.bulk-order-item-in-stock-input .MuiOutlinedInput-root {
  border-radius: 8px !important;
}

.order-item-in-stock-input .MuiInputBase-root.Mui-disabled,
.order-project-input .MuiInputBase-root.Mui-disabled {
  background-color: #F2F4F7 !important;
  border-radius: 8px !important;
}
.order-item-in-stock-input[disabled] div:last-child{
  background-color: #F2F4F7 !important;
}

.order-item-in-stock-input .item-in-stock-search-wrapper {
  cursor: pointer;
  width: 53px;
}

.order-item-in-stock-input .item-in-stock-search-wrapper:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.order-item-in-stock-input .item-in-stock-search-wrapper[aria-disabled="true"] {
  background-color: red !important;
  pointer-events: none;
}

.order-item-in-stock-input .item-in-stock-search-button {
  width: 53px;
  height: 100%;
  border-radius: 0;
}

.order-items-quantity-input input.MuiOutlinedInput-input {
  text-align: right;
  padding: 8.5px 14px !important;
 }


.bulk-order-item-quantity-input input.MuiOutlinedInput-input {
  text-align: right;
  padding: 12px 14px !important;
}