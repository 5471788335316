.tenant-dropdown__value-container {
  padding: 0;
  padding-right: 6px;
  margin-bottom: 2px;
}

.tenant-dropdown__single-value {
  font-family: Inter !important;
  font-size: 14px !important;
  color: var(--Gray-900);
}

.tenant-dropdown__menu-notice,
.tenant-dropdown__menu-notice--no-options {
  font-size: 14px !important;
}

.tenant-dropdown__input {
  font-size: 11px !important;
}

.tenant-dropdown__option {
  font-family: Arial, sans-serif !important;
  font-size: 14px !important;
}
.tenant-dropdown__option:hover {
  background-color: var(--blue300) !important;
  color: var(--grey900) !important;
  cursor: pointer !important;
}
.tenant-dropdown__option:focus {
  background-color: var(--blue900) !important;
  color: var(--grey900) !important;
}

.tenant-dropdown__dropdown-indicator {
  padding: 3px !important;
  transform: scale(0.75);
}

.tenant-dropdown__control {
  width: max-content;
  min-height: 42px;
  height: 42px;
  background-color: transparent;
  color: #666666;
  border: none !important;
  box-shadow: none !important;
  cursor: pointer;
}

.tenant-dropdown__control--is-focused,
.tenant-dropdown__contro--is-open {
  border: none;
}

.tenant-dropdown__single-value {
  overflow: visible !important;
  padding-right: 16px;
}
.tenant-dropdown__option--is-selected {
  background-color: var(--blue300) !important;
  color: var(--grey900) !important;
}

.tenant-dropdown__menu {
  z-index: 1010;
  width: 220px;
  color: #666666;
}
