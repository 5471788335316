.heading {
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
  font-family: Helvetica;
  font-weight: bold;
  font-size: 22px;
  margin: 0;
  padding: 0;
}

.content {
  margin-top: 12px !important;
  text-align: left;
  font-size: 20px;
  color: #707070;
  font-family: Helvetica;
}

.remove-margin-top {
  margin-top: 0px;
}

.button {
  height: 51px !important;
  border-radius: 3px !important;
  opacity: 1 !important;
}

.term-and-policy {
  margin-top: 35px;
  font-family: Arial, Helvetica, sans-serif;
  color: #666666;
}

.two-line-truncate {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 2em;
  line-height: 1em;
}

.scroll-container::-webkit-scrollbar {
  width: 8px;
}

.scroll-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scroll-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.scroll-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.quantity-box {
  height: 51px !important;
  width: 46px !important;
}

.delete-icon:hover {
  cursor: pointer;
  color: red;
}

@media (max-width: 900px) {
  .products-columns {
    display: none;
  }
}

@media (max-width: 381px) {
  .buttons-stack {
    flex-direction: column;
  }
}

.stack-button{
  margin: 4px;
}

@media (max-width: 600px) {
  .product-desc{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}

@media (min-width: 900px) {
  .quantity-column {
    display: none;
  }
}

@media (min-width: 600px) {
  .desc-width {
    width: 500px;
  }
}

@media (min-width: 600px) {
  .mark-as-purchase {
    width: 450px;
  }
}
@media (max-width: 375px) {
  .mark-as-purchase {
    width: 140px;
  }
}
@media (min-width: 375px) and (max-width: 600px) {
  .mark-as-purchase {
    width: 250px;
  }
}

.stack-container {
  display: flex;
  flex-direction: row;
  gap: 120px;
}

@media (max-width: 700px) {
  .stack-container {
    flex-direction: column;
  }
}
